<template>
  <div>
    <b-card title="Кредитный Регистр" class="mb-0">
      <!-- Filters -->
      <div class="d-flex flex-wrap mb-2">
        <b-form-group
          label="Поиск пользователя:"
          label-for="user-search"
          class="mr-1 mb-1"
        >
          <b-input-group>
            <b-form-input
              id="user-search"
              v-model="searchQuery"
              placeholder="Поиск..."
              @keyup.enter="fetchRegisterRequests"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="fetchRegisterRequests">
                <feather-icon icon="SearchIcon" size="16" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-button
          variant="outline-secondary"
          class="mb-1"
          @click="resetFilters"
        >
          <feather-icon icon="RefreshCwIcon" size="16" class="mr-50" />
          Сбросить
        </b-button>
      </div>

      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4 class="mb-0">Запросы</h4>
        <div class="d-flex gap-1">
          <b-spinner v-if="loading" small />
        </div>
      </div>

      <b-table
        :items="registerRequests"
        :fields="fields"
        responsive
        hover
        striped
        class="mb-0"
      >
        <!-- Request Type Column -->
        <template #cell(request_type_display)="data">
          <span>{{ data.value }}</span>
        </template>

        <!-- User Column -->
        <template #cell(user_display)="data">
          <span>{{ data.value || '-' }}</span>
        </template>
        
        <!-- Request Data Column -->
        <template #cell(request_data)="data">
          <div v-if="data.value" class="request-data-container">
            <span 
              v-b-tooltip.hover.html
              :title="formatRequestDataTooltip(data.value)"
              class="request-data-info"
            >
              <feather-icon icon="InfoIcon" size="14" />
              <span class="ml-1">Данные запроса</span>
            </span>
          </div>
          <span v-else>-</span>
        </template>

        <!-- Request File Column -->
        <template #cell(request_file)="data">
          <b-button
            v-if="data.value"
            variant="outline-primary"
            size="sm"
            @click="openFile(data.value)"
          >
            <feather-icon icon="FileIcon" class="mr-50" size="16" />
            Запрос
          </b-button>
        </template>

        <!-- Response File Column -->
        <template #cell(response_file)="data">
          <b-button
            v-if="data.value"
            variant="outline-primary"
            size="sm"
            @click="openFile(data.value)"
          >
            <feather-icon icon="FileIcon" class="mr-50" size="16" />
            Ответ
          </b-button>
        </template>

        <!-- HTML Report Column -->
        <template #cell(html_report)="data">
          <b-button
            v-if="data.value"
            variant="outline-primary"
            size="sm"
            @click="openFile(data.value)"
          >
            <feather-icon icon="FileTextIcon" class="mr-50" size="16" />
            Отчет
          </b-button>
        </template>

        <!-- Created Date Column -->
        <template #cell(created)="data">
          {{ formatDate(data.value) }}
        </template>

        <!-- Modified Date Column -->
        <template #cell(modified)="data">
          {{ formatDate(data.value) }}
        </template>

        <!-- Response Data Column -->
        <template #cell(response_data)="data">
          <div v-if="data.value" class="response-data-wrapper">
            <div class="response-badge-container">
              <b-badge v-if="data.value.errorcode === '0'" variant="success">
                {{ data.value.description || 'Успешно' }}
              </b-badge>
              <b-badge v-else variant="danger">
                {{ data.value.description || 'Ошибка' }}
              </b-badge>
            </div>
            <b-button
              v-b-tooltip.hover
              title="Подробная информация"
              size="sm"
              variant="outline-secondary"
              class="response-info-button"
              @click="showResponseData(data.value)"
            >
              <feather-icon icon="InfoIcon" size="14" />
            </b-button>
          </div>
          <span v-else>-</span>
        </template>
      </b-table>

      <div v-if="!registerRequests.length && !loading" class="text-center my-3">
        <p>Данных не найдено</p>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'CreditRegisterList',
  data() {
    return {
      loading: false,
      registerRequests: [],
      searchQuery: '',
      fields: [
        {
          key: 'full_name',
          label: 'ФИО',
          sortable: true,
        },
        {
          key: 'request_type_display',
          label: 'Тип запроса',
          sortable: true,
        },
        {
          key: 'request_data',
          label: 'Данные запроса',
          tdClass: 'request-data-column',
          thClass: 'request-data-column',
        },
        {
          key: 'request_file',
          label: 'Файл запроса',
        },
        {
          key: 'response_file',
          label: 'Файл ответа',
        },
        {
          key: 'response_data',
          label: 'Ответ',
          tdClass: 'response-data-column',
          thClass: 'response-data-column',
        },
        {
          key: 'html_report',
          label: 'HTML отчет',
        },
        {
          key: 'created',
          label: 'Создан',
          sortable: true,
        },
      ],
    }
  },
  created() {
    // If search param exists in URL, set it and fetch with that
    const searchFromQuery = this.$route.query.search
    if (searchFromQuery) {
      this.searchQuery = searchFromQuery
    }
    this.fetchRegisterRequests()
  },
  methods: {
    async fetchRegisterRequests() {
      this.loading = true
      try {
        const params = {}
        if (this.searchQuery) {
          params.search = this.searchQuery

          // Update URL with search query
          this.$router.replace({
            query: { ...this.$route.query, search: this.searchQuery },
          })
        }

        const response = await this.$http.get('/credit-register/requests/', {
          params,
        })
        this.registerRequests = response.data.results
      } catch (error) {
        this.$toast.error('Ошибка при загрузке данных кредитного регистра')
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    resetFilters() {
      this.searchQuery = ''
      this.$router.replace({ query: {} })
      this.fetchRegisterRequests()
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      return new Date(dateString).toLocaleString('ru-RU')
    },
    openFile(fileUrl) {
      if (fileUrl) {
        window.open(fileUrl, '_blank')
      }
    },
    showResponseData(responseData) {
      if (!responseData) return
      
      this.$bvModal.msgBoxOk(JSON.stringify(responseData, null, 2), {
        title: 'Информация об ответе',
        size: 'lg',
        buttonSize: 'sm',
        centered: true,
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        contentClass: 'pre-formatted text-monospace'
      })
    },
    formatRequestDataTooltip(requestData) {
      if (!requestData) return ''
      
      try {
        // For single-level objects without nesting
        const rows = []
        
        // Create a table format for the tooltip
        rows.push('<table class="tooltip-table">')
        
        Object.entries(requestData).forEach(([key, value]) => {
          rows.push(`<tr><td class="key">${key}:</td><td>${value}</td></tr>`)
        })
        
        rows.push('</table>')
        
        return rows.join('')
      } catch (error) {
        console.error('Error formatting request data tooltip:', error)
        return 'Ошибка форматирования данных'
      }
    },
  },
}
</script>

<style>
.pre-formatted {
  white-space: pre-wrap;
  max-height: 500px;
  overflow-y: auto;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.25rem;
}

.request-data-container {
  display: flex;
  align-items: center;
  cursor: help;
}

.request-data-info {
  display: inline-flex;
  align-items: center;
  color: #7367f0;
  font-size: 0.9rem;
}

.tooltip-table {
  border-collapse: collapse;
  font-family: monospace;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 4px;
}

.tooltip-table td {
  padding: 2px 6px;
  border-bottom: 1px solid #eee;
  text-align: left;
  white-space: nowrap;
}

.tooltip-table td.key {
  font-weight: bold;
  color: #7367f0;
}

/* Fixed width for data columns */
.response-data-column,
.request-data-column {
  width: 200px;
  max-width: 200px;
}

/* Response data styling */
.response-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
}

.response-badge-container {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.response-badge-container .badge {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.response-info-button {
  flex-shrink: 0;
  margin-left: 4px;
}
</style>
